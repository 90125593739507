var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-modal', {
          ref: "modalEdit",
          attrs: {
            "id": "schedule-tag-modify-change",
            "header-bg-variant": "light-info",
            "centered": "",
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "text-heading-3 m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('scheduleTag.edit.title')) + " ")])];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn() {
              return [_c('b-button', {
                attrs: {
                  "variant": "outline-danger",
                  "pill": "",
                  "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
                },
                on: {
                  "click": _vm.handleCancelEdit
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('scheduleCalendar.edit.cancel')) + " ")]), _c('b-button', {
                attrs: {
                  "variant": "gradient",
                  "pill": "",
                  "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
                  "disabled": invalid
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('scheduleCalendar.edit.save')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form', {
          ref: "form"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('scheduleTag.content'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "content"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('h5', [_vm._v(_vm._s(_vm.$t('scheduleTag.content')))])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "content",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "placeholder": _vm.$t('scheduleTag.content')
                },
                model: {
                  value: _vm.dataToEdit.content,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "content", $$v);
                  },
                  expression: "dataToEdit.content"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              })];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }