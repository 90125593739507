<template>
  <div class="d-flex align-items-center justify-content-lg-center justify-content-end flex-wrap flex-lg-nowrap">
    <swiper
      id="slider-component"
      class="swiper-multiple px-1 px-lg-4 py-1"
      :options="swiperOptions"
    >
      <!-- ANCHOR slides -->
      <swiper-slide
        v-for="(day, index) in workingDays"
        :key="index"
        class="slide"
        style="max-width: 100px;"
      >
        <b-card
          no-body
          class="text-center text-dark mb-0 d-flex-center justify-content-start mr-0 px-0 h-100"
          :class="`${day === workDate ? 'btn bg-slider-active font-weight-bold'
            : 'btn font-weight-bold text-white'
          }`"
          @click="handleClickTab(day)"
        >
          <b-card-body class="p-0 d-flex flex-column justify-content-between">
            <div class="text-nowrap text-xl">
              {{ $t(getTextDay(day)) }}
            </div>
            <div class="text-nowrap">
              {{ formatDateMonth(day, 'DD/MM') }}
            </div>
          </b-card-body>
        </b-card>
      </swiper-slide>

      <!-- ANCHOR Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next btn-button"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev btn-button"
      />
    </swiper>

    <b-button
      variant="info"
      class="mx-50 mx-md-1 mt-1 mt-lg-0"
      :disabled="!canAccessSchedule"
      @click="() => $router.push({ name: 'apps-workSchedules-create', query: { type: employeeType ? 'SE' : 'BE'} })"
    >
      <span class="text-nowrap">
        <feather-icon
          v-if="!isMobileView"
          class="cursor-pointer"
          icon="PlusSquareIcon"
          size="16"
        />
        {{ $t('schedule.create.title') }}
      </span>
    </b-button>
  </div>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'
import {
  BCard, BCardBody, BButton,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import store from '@/store'
import {
  WORK_SCHEDULE_APP_STORE_MODULE_NAME,
} from '@/constants/schedule'

import { getMoment, formatDateMonth, getTextDay } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardBody,
    Swiper,
    SwiperSlide,
    BButton,
  },

  computed: {
    workDate: {
      get() { return this.$store.getters['app-work-schedule/getWorkDate'] },
      set(newValue) { this.$store.dispatch('app-work-schedule/setWorkDate', newValue) },
    },

    employeeType: {
      get() { return this.$store.getters['app-work-schedule/getEmployeeType'] },
    },
  },

  setup(props, { emit }) {
    // swiper
    const swiperOptions = ref({
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 10,
      breakpoints: {
        1280: {
          slidesPerView: 7,
          slidesPerGroup: 7,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        640: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    })

    const workingDays = Array.from({ length: 28 }).map((_, index) => formatDateMonth(getMoment().add({ days: index }), 'YYYY-MM-DD'))

    const meData = computed(() => store.getters['userStore/getMeData'])

    const employeeTypeSchedule = computed(() => store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getEmployeeType`])

    const isMama = computed(() => store.getters['userStore/getRoleMama'])
    const f2 = computed(() => store.getters['userStore/getRoleF2'])
    const f3 = computed(() => store.getters['userStore/getRoleCTV'])
    const canAccessSchedule = computed(() => {
      const type = meData?.value?.type
      if (type
        && (
          f3.value || (f2.value && !['ADM', 'OPE'].includes(type))
          || (isMama.value && employeeTypeSchedule.value === 1 && !['ADM', 'OPE', 'SE'].includes(type))
          || (isMama.value && employeeTypeSchedule.value === 0 && !['ADM', 'OPE', 'BE'].includes(type))
        )) {
        return false
      }
      return true
    })

    const action = ref('')
    const refId = ref('')

    const emptyFilters = computed(() => !(action.value || refId.value))

    function handleClickTab(day) {
      this.$store.dispatch('app-work-schedule/setWorkDate', day)
      emit('refetch-data-with-filters')
    }

    return {
      action,
      refId,
      emptyFilters,

      workingDays,
      getTextDay,
      formatDateMonth,
      getMoment,
      swiperOptions,
      handleClickTab,

      canAccessSchedule,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

#slider-component ::v-deep {
  .swiper-slide {
    color: white;
    height: 64px;
  }

  .swiper-button-prev {
    &:after {
      background-image: url(str-replace(str-replace($chevron-left, 'currentColor', $primaryV2), '#', '%23')) !important;
    }
  }

  .swiper-button-next {
    right: 0;

    &:after {
      background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $primaryV2), '#', '%23')) !important;
    }
  }

  .swiper-button-prev.btn-button,
  .swiper-button-next.btn-button {
    margin-top: 0;
    height: 70%;
    translate: 0 -50%;

    &:hover {
      background: rgba(#ccc, 0.3);
      backdrop-filter: blur(5px);
      border-radius: 8px;
    }
  }
}

.bg-slider-active {
  background-color: $primaryV2;
  color: white !important;
}

.text-xl {
  font-size: 20px;
}
</style>
