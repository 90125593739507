<template>
  <div>
    <b-row>
      <b-col sm="6">
        <div class="bg-white">
          <b-tabs
            v-model="employeeType"
            fill
            pills
            nav-class="p-1 mb-1 mb-md-0"
            active-nav-item-class="tab-active border-0"
          >
            <b-tab
              v-for="(feature, key) in EMPLOYEE_TYPES"
              :key="key"
            >
              <template #title>
                <span>
                  {{ key.toUpperCase() }}
                </span>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
      <b-col sm="6">
        <div class="bg-white p-1 d-flex align-items-center justify-content-center">
          <b-tabs
            v-model="scheduleType"
            fill
            pills
            nav-class="m-0"
            class="w-100"
            active-nav-item-class="tab-active border-0"
          >
            <b-tab :disabled="!isToday">
              <template #title>
                <span>
                  {{ $t('schedule.currentSchedule').toUpperCase() }}
                </span>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <span>
                  {{ $t('schedule.nextSchedule').toUpperCase() }}
                </span>
              </template>
            </b-tab>
          </b-tabs>
          <b-button
            variant="info"
            class="ml-50 ml-md-1"
            :disabled="!canAccessSchedule"
            @click="modalShow = !modalShow"
          >
            <span class="text-nowrap">
              <feather-icon
                v-if="!isMobileView"
                class="cursor-pointer"
                icon="PlusSquareIcon"
                size="16"
              />
              {{ $t('scheduleCalendar.create.title') }}
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <WorkScheduleList />
    <ScheduleCalendarList
      :modal-show="modalShow"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  BTab, BTabs, BCol, BRow, BButton,
} from 'bootstrap-vue'
import { computed, onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'
import {
  WORK_SCHEDULE_APP_STORE_MODULE_NAME,
  EMPLOYEE_TYPES,
} from '@/constants/schedule'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import WorkScheduleList from './WorkScheduleList.vue'
import WorkScheduleStoreModule from './WorkScheduleStoreModule'
import ScheduleCalendarList from './schedule-calendar/index.vue'
import useScheduleList from './useScheduleList'

export default {
  components: {
    BCol,
    BButton,
    BRow,
    BTab,
    BTabs,
    WorkScheduleList,
    ScheduleCalendarList,
    FeatherIcon,
  },
  computed: {
    employeeType: {
      get() {
        return this.$store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getEmployeeType`]
      },
      set(val) {
        this.$store.dispatch(`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/setEmployeeType`, val)
      },
    },
    scheduleType: {
      get() {
        return this.$store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getScheduleType`]
      },
      set(val) {
        this.$store.dispatch(`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/setScheduleType`, val)
      },
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)) store.registerModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME, WorkScheduleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)) store.unregisterModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)
    })

    const meData = computed(() => store.getters['userStore/getMeData'])

    const employeeTypeSchedule = computed(() => store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getEmployeeType`])

    const isToday = computed(() => store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getWorkDate`] === moment().format('YYYY-MM-DD'))
    const isMama = computed(() => store.getters['userStore/getRoleMama'])
    const f2 = computed(() => store.getters['userStore/getRoleF2'])
    const f3 = computed(() => store.getters['userStore/getRoleCTV'])
    const canAccessSchedule = computed(() => {
      const type = meData?.value?.type
      if (type
        && (
          f3.value || (f2.value && !['ADM', 'OPE'].includes(type))
          || (isMama.value && employeeTypeSchedule.value === 1 && !['ADM', 'OPE', 'SE'].includes(type))
          || (isMama.value && employeeTypeSchedule.value === 0 && !['ADM', 'OPE', 'BE'].includes(type))
        )) {
        return false
      }
      return true
    })

    const { refetchData } = useScheduleList()
    const modalShow = ref(false)

    return {
      EMPLOYEE_TYPES,
      refetchData,
      modalShow,
      canAccessSchedule,
      isToday,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.tab-active {
  background-color: $primaryV2 !important;
}
</style>
