<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <b-modal
      id="schedule-calendar-modify-change"
      ref="modalEdit"
      header-bg-variant="light-info"
      centered
      size="lg"
    >
      <template v-slot:modal-header>
        <h4 class="text-heading-3 m-0">
          {{ $t('scheduleCalendar.edit.title') }}
        </h4>
      </template>

      <template
        v-slot:modal-footer
      >
        <b-button
          variant="outline-danger"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleCancelEdit"
        >
          {{ $t('scheduleCalendar.edit.cancel') }}
        </b-button>
        <b-button
          variant="gradient"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
          @click="handleEdit"
        >
          {{ $t('scheduleCalendar.edit.save') }}
        </b-button>
      </template>

      <b-form ref="form">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-row>
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  :name="$t('scheduleCalendar.name')"
                  rules="required"
                >
                  <b-form-group
                    label-for="name"
                  >
                    <template #label>
                      <h5>{{ $t('scheduleCalendar.name') }}</h5>
                    </template>
                    <b-form-input
                      id="name"
                      v-model="dataToEdit.name"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :placeholder="$t('scheduleCalendar.name')"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) of dataToEdit.scheduleCalendarTimes"
              :key="index"
            >
              <b-col md="auto">
                <validation-provider
                  #default="validationContext"
                  :name="$t('scheduleCalendar.startTime')"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="startTime">{{ $t('scheduleCalendar.startTime') }}</label>
                    </div>
                    <b-input-group>
                      <b-form-input
                        :id="`startTime-${index}`"
                        v-model="item.startTime"
                        type="time"
                        format="HH:mm"
                        :placeholder="$t('scheduleCalendar.startTime')"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          :id="`startTime-${index}`"
                          v-model="item.startTime"
                          minutes-step="10"
                          right
                          locale="en"
                          :hour12="false"
                          format="HH:mm"
                          button-only
                          @input="handleInputStartTime(item.startTime, index)"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                </validation-provider>
              </b-col>

              <b-col md="auto">
                <validation-provider
                  #default="validationContext"
                  :name="$t('scheduleCalendar.endTime')"
                  rules="required"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="endTime">{{ $t('scheduleCalendar.endTime') }}</label>
                    </div>
                    <b-input-group>
                      <b-form-input
                        :id="`endTime-${index}`"
                        v-model="item.endTime"
                        type="time"
                        format="HH:mm"
                        :placeholder="$t('scheduleCalendar.endTime')"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          :id="`endTime-${index}`"
                          v-model="item.endTime"
                          minutes-step="10"
                          right
                          locale="en"
                          :hour12="false"
                          format="HH:mm"
                          button-only
                          @input="handleInputEndTime(item.endTime, index)"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                </validation-provider>
              </b-col>

              <b-col
                v-if="dataToEdit.scheduleCalendarTimes.length > 1 && index <= dataToEdit.scheduleCalendarTimes.length - 1"
                md="auto"
                class="d-flex align-items-center my-1"
              >
                <b-button
                  variant="info"
                  @click="decreaseNumberTimes(index)"
                >
                  <feather-icon
                    icon="MinusIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                xs="auto"
                class="d-flex align-items-center my-1"
              >
                <b-button
                  variant="info"
                  @click="increaseNumberTimes()"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInvalidFeedback,
  BFormGroup,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  ref,
  watch,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'
import api from '@/api/'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required, dateFormat } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BModal,
    BButton,
    BForm,
    BFormTimepicker,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    scheduleCalendarData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dataToEdit = ref(cloneDeep(props.scheduleCalendarData))

    const resetScheduleCalendarDataToEdit = () => {
      dataToEdit.value = cloneDeep(props.scheduleCalendarData)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(
      resetScheduleCalendarDataToEdit,
    )

    watch(() => props.scheduleCalendarData, val => {
      dataToEdit.value = cloneDeep(val)
    }, { deep: true })

    // Cancel Edit Contact
    function handleCancelEdit() {
      this.$refs.modalEdit.hide()
      resetForm()
    }

    function handleInputEndTime(time, index) {
      if (time === '00:00:00') { dataToEdit.value.scheduleCalendarTimes[index].endTime = '23:59' }
    }

    function handleInputStartTime(time, index) {
      if (time === '00:00:00') { dataToEdit.value.scheduleCalendarTimes[index].startTime = '23:59' }
    }

    function increaseNumberTimes() {
      dataToEdit.value.scheduleCalendarTimes.push({ startTime: '', endTime: '' })
    }

    function decreaseNumberTimes(index) {
      dataToEdit.value.scheduleCalendarTimes.splice(index, 1)
    }

    // handle edit contact
    function handleEdit() {
      const dataToUpdate = getDifference(dataToEdit.value, props.scheduleCalendarData)
      store.dispatch('app/setLoading', true)
      if (dataToUpdate.scheduleCalendarTimes) {
        dataToUpdate.scheduleCalendarTimes.forEach(item => {
          item.startTime = item.startTime.split(':').slice(0, 2).join(':')
          item.endTime = item.endTime.split(':').slice(0, 2).join(':')
        })
      }
      api.scheduleCalendar.updateScheduleCalendar(props.scheduleCalendarData.id, dataToUpdate)
        .then(() => {
          emit('refetch-data')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('scheduleCalendar.editScheduleCalendarSuccess'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.$refs.modalEdit.hide()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('scheduleCalendar.editScheduleCalendarError'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    return {
      dataToEdit,
      handleCancelEdit,
      handleEdit,
      required,
      dateFormat,

      refFormObserver,
      getValidationState,
      resetForm,

      handleInputEndTime,
      handleInputStartTime,
      increaseNumberTimes,
      decreaseNumberTimes,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
