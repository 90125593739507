<template>
  <div>
    <b-modal
      :visible="active"
      centered
      size="lg"
      scrollable
      content-class="mh-75"
      hide-footer
      @change="handleCancel"
    >
      <template #modal-title>
        {{ $t('scheduleCalendar.create.title') }}
      </template>
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1 position-relative"
          sticky-header
        >
          <template #header>
            <h6 class="m-0">
              {{ $t('scheduleCalendar.titleList') }}
            </h6>
          </template>

          <b-card-body class="p-0">
            <b-overlay
              :show="false"
              rounded="sm"
              no-fade
              variant="transparent"
              :opacity="0.5"
              class="py-1"
            >
              <b-table
                sticky-header
                responsive
                class="position-relative"
                :empty-text="$t('noMatchingResult')"
                :items="scheduleCalendarConfigsData"
                :fields="[
                  'name',
                  'scheduleCalendarTimes',
                  'action'
                ]"
              >
                <template #head(name)>
                  <div class="text-nowrap">
                    {{ $t('scheduleCalendar.name') }}
                  </div>
                </template>
                <template #cell(name)="{ item }">
                  <div class="text-nowrap">
                    {{ item.name }}
                  </div>
                </template>
                <template #head(scheduleCalendarTimes)>
                  <div class="text-nowrap">
                    {{ $t('scheduleCalendar.scheduleCalendarTimes') }}
                  </div>
                </template>
                <template #cell(scheduleCalendarTimes)="{ item }">
                  <b-row>
                    <b-col
                      v-for="(time, index) in item.scheduleCalendarTimes"
                      :key="index"
                      md="auto"
                    >
                      <b-badge>
                        {{ time.startTime }} - {{ time.endTime }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </template>
                <template #head(note)>
                  {{ $t('scheduleCalendar.note') }}
                </template>
                <template #cell(action)="data">
                  <div class="d-flex gap-2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.scheduleCalendar-modify-change-note-modal
                      title="Delete"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="confirmDelete(data.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        style="cursor: pointer"
                      />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.schedule-calendar-modify-change
                      title="Edit"
                      variant="outline-info"
                      class="btn-icon"
                      @click="editScheduleCalendar(data.item, $event)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        style="cursor: pointer"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-overlay>
            <div>
              <b-row no-gutters>
                <b-col
                  cols="12"
                  md=""
                  class="mright-1"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('scheduleCalendar.name')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="name"
                      class="max-w-75"
                    >
                      <template #label>
                        <h5>{{ $t('scheduleCalendar.name') }}</h5>
                      </template>
                      <b-form-input
                        id="name"
                        v-model="scheduleCalendarToAdd.name"
                        :state="getValidationState(validationContext) === false ? false : null"
                        class="h-41"
                        lazy-formatter
                        :placeholder="$t('scheduleCalendar.name')"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                  </validation-provider>
                </b-col>
                <b-col xs="auto">
                  <div
                    v-for="(item,index) of scheduleCalendarTimes"
                    :key="index"
                    class="d-flex flex-column gap-1 flex-xl-row mb-1"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('scheduleCalendar.startTime')"
                      rules="required"
                    >
                      <b-form-group class="mb-0">
                        <label for="startTime">{{ $t('scheduleCalendar.startTime') }}</label>
                        <b-input-group class="flex-nowrap">
                          <b-form-input
                            :id="`startTime-${index}`"
                            v-model="item.startTime"
                            type="time"
                            style="height: auto"
                            format="HH:mm"
                            :placeholder="$t('scheduleCalendar.startTime')"
                          />
                          <b-input-group-append>
                            <b-form-timepicker
                              :id="`startTime-${index}`"
                              v-model="item.startTime"
                              minutes-step="10"
                              right
                              locale="en"
                              :hour12="false"
                              format="HH:mm"
                              button-only
                              @input="handleInputStartTime(item.startTime, index)"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                    </validation-provider>

                    <validation-provider
                      #default="validationContext"
                      :name="$t('scheduleCalendar.endTime')"
                      rules="required"
                    >
                      <b-form-group class="mb-0">
                        <label for="endTime">{{ $t('scheduleCalendar.endTime') }}</label>
                        <b-input-group class="flex-nowrap">
                          <b-form-input
                            :id="`endTime-${index}`"
                            v-model="item.endTime"
                            type="time"
                            style="height: auto;"
                            format="HH:mm"
                            :placeholder="$t('scheduleCalendar.endTime')"
                          />
                          <b-input-group-append>
                            <b-form-timepicker
                              :id="`endTime-${index}`"
                              v-model="item.endTime"
                              minutes-step="10"
                              right
                              locale="en"
                              :hour12="false"
                              format="HH:mm"
                              button-only
                              @input="handleInputEndTime(item.endTime, index)"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                    </validation-provider>

                    <div class="d-flex align-items-end">
                      <b-button
                        v-if="scheduleCalendarTimes.length > 1 && index <= scheduleCalendarTimes.length - 1"
                        variant="info"
                        class="mright-1 px-1 h-41"
                        @click="decreaseNumberTimes(index)"
                      >
                        <feather-icon
                          icon="MinusIcon"
                        />
                      </b-button>
                      <b-button
                        variant="info"
                        class="px-1 h-41"
                        @click="increaseNumberTimes()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex justify-content-end">
                  <b-button
                    class="ml-1"
                    variant="info"
                    :disabled="isLoading || invalid"
                    @click="createScheduleCalendar()"
                  >
                    {{ $t('scheduleCalendar.create.title') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>

      </validation-observer>

      <validation-observer
        #default="{ invalid }"
        ref="refFormObserverTag"
      >
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1 position-relative"
          sticky-header
        >
          <template #header>
            <h6 class="m-0">
              {{ $t('scheduleTag.titleList') }}
            </h6>
          </template>

          <b-card-body class="p-0">
            <b-overlay
              :show="false"
              rounded="sm"
              no-fade
              variant="transparent"
              :opacity="0.5"
              class="py-1"
            >
              <b-table
                sticky-header
                responsive
                class="position-relative"
                :empty-text="$t('noMatchingResult')"
                :items="scheduleTagConfigsData"
                :fields="[
                  'content',
                  'action'
                ]"
              >
                <template #head(content)>
                  {{ $t('scheduleTag.content') }}
                </template>
                <template #cell(action)="data">
                  <div class="d-flex gap-2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      title="Delete"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="confirmDeleteTag(data.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        style="cursor: pointer"
                      />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.schedule-tag-modify-change
                      title="Edit"
                      variant="outline-info"
                      class="btn-icon"
                      @click="editScheduleTag(data.item, $event)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        style="cursor: pointer"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-overlay>
            <div>
              <b-row
                v-for="(item, index) of scheduleTagToAdd"
                :key="index"
              >
                <b-col
                  md="auto"
                  class="d-flex w-100"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('scheduleTag.content')"
                    rules="required"
                    class="flex-fill"
                  >
                    <b-form-group
                      label-for="content"
                    >
                      <template #label>
                        <h5>{{ $t('scheduleTag.content') }}</h5>
                      </template>
                      <b-form-input
                        :id="`content-${index}`"
                        v-model="item.content"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :placeholder="$t('scheduleTag.content')"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)" />
                  </validation-provider>
                  <div class="d-flex align-items-end my-1 ml-1">
                    <b-button
                      v-if="scheduleTagToAdd.length > 1 && index <= scheduleTagToAdd.length - 1"
                      variant="info"
                      class="mright-1"
                      @click="decreaseScheduleTag(index)"
                    >
                      <feather-icon
                        icon="MinusIcon"
                      />
                    </b-button>
                    <b-button
                      variant="info"
                      @click="increaseScheduleTag()"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex justify-content-end">
                  <b-button
                    class="ml-1"
                    variant="info"
                    :disabled="isLoading || invalid"
                    @click="createScheduleTag()"
                  >
                    {{ $t('scheduleTag.create.title') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </validation-observer>
    </b-modal>

    <EditModalTag
      :schedule-tag-data.sync="scheduleTagData"
      @refetch-data="getScheduleTagsData"
    />

    <EditModal
      :schedule-calendar-data.sync="scheduleCalendarData"
      @refetch-data="getScheduleCalendarsData"
    />
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
  BBadge,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'

import api from '@/api/'

import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import EditModalTag from './EditModalTag.vue'
import EditModal from './EditModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BBadge,
    BFormTimepicker,
    BInputGroup,
    BInputGroupAppend,
    EditModal,
    EditModalTag,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scheduleCalendarToAdd: {},
      scheduleCalendarTimes: [{ startTime: '', endTime: '' }],
      scheduleCalendarConfigsData: [],

      scheduleTagConfigsData: [],
      scheduleTagToAdd: [{ content: '' }],
      isLoading: false,
    }
  },
  setup(props) {
    const { getValidationState } = formValidation()

    const scheduleCalendarData = ref({})
    const scheduleTagData = ref({})

    async function editScheduleCalendar(scheduleCalendar, event) {
      await new Promise(resolve => {
        scheduleCalendarData.value = scheduleCalendar
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'schedule-calendar-modify-change', event)
    }

    async function editScheduleTag(scheduleTag, event) {
      await new Promise(resolve => {
        scheduleTagData.value = scheduleTag
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'schedule-tag-modify-change', event)
    }

    const active = ref(props.modalShow)
    watch(() => props.modalShow, () => {
      active.value = true
    })

    return {
      getValidationState,
      editScheduleCalendar,
      scheduleCalendarData,
      active,

      editScheduleTag,
      scheduleTagData,
    }
  },

  computed: {
    meData() {
      return this.$store.getters['userStore/getMeData']
    },
  },

  mounted() {
    this.getScheduleCalendarsData()
    this.getScheduleTagsData()
  },
  methods: {
    handleInputEndTime(time, index) {
      if (time === '00:00:00') { this.scheduleCalendarTimes[index].endTime = '23:59' }
    },

    handleInputStartTime(time, index) {
      if (time === '00:00:00') { this.scheduleCalendarTimes[index].startTime = '23:59' }
    },

    handleCancel(isVisible) {
      if (!isVisible) {
        this.scheduleCalendarToAdd = {}
        this.scheduleCalendarTimes = [{ startTime: '', endTime: '' }]
        this.active = false
      }
    },

    async getScheduleCalendarsData() {
      this.$bvModal.show('modal-api-loading')
      // await this.$store.dispatch('userStore/fetchMeData')
      const data = await api.scheduleCalendar.fetchScheduleCalendars()
      this.scheduleCalendarConfigsData = data.data.items.map(item => ({
        id: item.id,
        scheduleCalendarTimes: item.scheduleCalendarTimes,
        name: item.name,
        note: item.note,
      }))
      this.$bvModal.hide('modal-api-loading')
    },

    async getScheduleTagsData() {
      this.$bvModal.show('modal-api-loading')
      const data = await api.scheduleTag.fetchScheduleTags()
      this.scheduleTagConfigsData = data.data.items.map(item => ({
        id: item.id,
        content: item.content,
      }))

      this.$bvModal.hide('modal-api-loading')
    },

    confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: 'Are you sure to delete schedule calendar?' } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'DELETE CONFIRM',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await api.scheduleCalendar.deleteScheduleCalendar(id)
            await this.getScheduleCalendarsData()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('scheduleCalendar.deleteScheduleCalendarSuccess'),
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
    },

    confirmDeleteTag(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: 'Are you sure to delete schedule tag?' } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'DELETE CONFIRM',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await api.scheduleTag.deleteScheduleTag(id)
            await this.getScheduleTagsData()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('scheduleTag.deleteScheduleTagSuccess'),
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
    },

    increaseNumberTimes() {
      this.scheduleCalendarTimes.push({ startTime: '', endTime: '' })
    },

    decreaseNumberTimes(index) {
      this.scheduleCalendarTimes.splice(index, 1)
    },

    increaseScheduleTag() {
      this.scheduleTagToAdd.push({ content: '' })
    },

    decreaseScheduleTag(index) {
      this.scheduleTagToAdd.splice(index, 1)
    },

    async createScheduleCalendar() {
      this.isLoading = true
      try {
        const payload = {
          scheduleCalendarTimes: this.scheduleCalendarTimes.map(item => ({
            startTime: item.startTime.split(':').slice(0, 2).join(':'),
            endTime: item.endTime.split(':').slice(0, 2).join(':'),
          })),
          ...this.scheduleCalendarToAdd,
          agencyId: this.meData.agency.id,
        }

        await api.scheduleCalendar.createScheduleCalendar(payload)
        await this.getScheduleCalendarsData()
        this.scheduleCalendarToAdd = {}
        this.scheduleCalendarTimes = [{ startTime: '', endTime: '' }]
        this.$refs.refFormObserver.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('scheduleCalendar.createScheduleCalendarSuccess'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('scheduleCalendar.createScheduleCalendarError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async createScheduleTag() {
      this.isLoading = true
      try {
        await api.scheduleTag.bulkInsertScheduleTag({
          scheduleTags: [...this.scheduleTagToAdd],
        })
        await this.getScheduleTagsData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('scheduleTag.createScheduleTagSuccess'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        this.scheduleTagToAdd = [{ content: '' }]
        this.$refs.refFormObserverTag.reset()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('scheduleTag.createScheduleTagError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

input[type="time"] {
  min-width: 160px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.mh-75 {
  max-height: 75vh !important;
}

.mright-1 {
  margin-right: 4px;
}

// height của select time
.h-41 {
  height: 41.5px;
}
</style>
